import React from "react";
import { graphql } from "gatsby";

import { mainNavigation, homesNavigation, products } from "src/data";
import Header from "components/Header";
import SEO from "components/SEO";
import Banner from "components/Banner";
import Layout from "components/Layout";
import IconText from "components/IconText";
import Products from "components/Products";
import Product from "components/Products/Product";
import BlogPreview from "components/BlogPreview";
import Instagram from "components/Instagram";
import Footer from "components/Footer";
import CookieBar from "components/CookieBar";

import "styles/styles.scss";

const IndexPage = ({ data }) => {
  const { logo, logoTop, hero, allImageContent, blogPosts, instagramPhotos } = data;
  return (
    <>
      <SEO />
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logoTop.childImageSharp.fluid}
        logoAlt="Moodu moduliniai namai logotipas"
      />
      <Banner
        heading="Moduliniai nameliai"
        text="Mobilūs ir individualiai pagaminti siekiant patenkinti kiekvieno asmeninius poreikius."
        imageData={hero.childImageSharp.fluid}
        buttonUrl="#namai"
        buttonText="Žiūrėti projektus"
        isSamePageUrl
      />
      <Layout>
        <IconText
          icon={logo.childImageSharp.fluid}
          iconAlt="moodu logotipas"
          text="Jūsų svajonių namas - mobili buveinė. Modulinės sistemos leidžia įgyvendinti daugybę derinių. Taip kiekvienas klientas gauna individualų ir unikalų produktą."
        />
        <div id="namai">
        <Products>
          {products.map(item => {
            const imageForProduct = allImageContent.edges.find(
              element => element.node.fluid.originalName === item.img.src
            );

            return (
              <Product
                key={item.id}
                title={item.title}
                img={imageForProduct.node.fluid}
                slug={item.slug}
                imgAlt={item.img.alt}
              />
            );
          })}
        </Products>
        </div>
        <BlogPreview posts={blogPosts.edges} />
        {instagramPhotos.edges.map((item, idx) => (
          <React.Fragment key={idx}>
            <Instagram
              heading="Sekite mus Instagram"
              username="moodu.eu"
              posts={item.node.photos}
            />
          </React.Fragment>
        ))}
      </Layout>
      <Footer logo={logoTop.childImageSharp.fluid} />
      <CookieBar />
    </>
  );
};

export const query = graphql`
  query FrontPage {
    hero: file(relativePath: { eq: "modulinis-namelis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoTop: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200 ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200 ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allImageContent: allImageSharp {
      edges {
        node {
          fluid(maxWidth: 500) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    blogPosts: allContentfulBlogPost(
      limit: 3
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          createdAt(formatString: "YYYY.MM.DD")
          title
          slug
        }
      }
    }
    instagramPhotos: allContentfulInstagram {
      edges {
        node {
          photos {
            fluid(maxWidth: 320) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
