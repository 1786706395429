import React from "react";
import Img from "gatsby-image";

import Heading from "components/Heading";

import "./_icon-text.scss";

const IconText = ({ icon, iconAlt, text }) => {
  return (
    <div className="icon-text">
      {icon && <Img fluid={icon} alt={iconAlt} className="icon-text__icon" />}
      <Heading headingLevel="h2" headingStyle="h3">
        {text}
      </Heading>
    </div>
  );
};

export default IconText;
