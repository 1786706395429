import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Heading from "components/Heading";
import Button from "components/Button";
import { TreesIcon } from "assets/icons";

import "./_blog-preview.scss";

const BlogPreview = ({ posts }) => {
  return (
    <div className="blog-preview">
      <TreesIcon className="blog-preview__bg" />
      <div className="blog-preview__content">
        <div className="blog-preview__wrapper">
          <Heading headingLevel="h2">Blogas</Heading>
          <p className="blog-preview__text">
            Kartais visi pritrūkstame idėjų. Tad norime Jums pagelbėti
            patarimais, kaip naudotis ir gyventi mobiliuose nameliuose ir
            paversti juos jaukesniais. Mūsų blog’e rasite įvairiausių patarimų
            pradedant stiliaus idėjomis ir baigiant techninių idėjų realizavimų.
            Mūsų komanda taip pat dalinsis patarimais, kaip išsirinkti
            geriausiai Jūsų poreikius atitinkantį namo projektą, o taip pat
            pamokys kaip pasiruošti sklypą prieš namelio atvežimą.
          </p>
          <Button url="/blogas" isDark>
            Visi įrašai
          </Button>
        </div>
        <div className="blog-preview__posts-block">
          {posts.map((post, idx) => (
            <div key={idx} className="blog-preview__post">
              <AniLink
                to={`/blogas/${post.node.slug}`}
                className="blog-preview__post-link"
                cover
                duration={1.5}
                bg="#3e563e"
              >
                {post.node.title}
              </AniLink>
              <time
                className="blog-preview__date"
                dateTime={post.node.createdAt}
              >
                {post.node.createdAt}
              </time>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPreview;
