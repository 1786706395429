import React from "react";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Heading from "components/Heading";

import "./_product.scss";

const Product = ({ title, img, imgAlt, slug }) => {
  return (
    <AniLink to={`/${slug}`} cover
    duration={1.5}
    bg="#3e563e" className="product">
      <Img fluid={img} alt={imgAlt} className="product__image" />
      <div className="product__title-wrapper">
        <Heading headingLevel="h2">{title}</Heading>
      </div>
    </AniLink>
  );
};

export default Product;
